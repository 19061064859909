import React from 'react'
// import { css } from '@emotion/react'
import { useStaticQuery, Link, graphql } from 'gatsby'

// import { rhythm } from '../../utils/typography'
import Sidebar from './sidebar/sidebar'
// import { mq } from '../../styles/theme'
import styles from './layout.module.scss'

export default function Layout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div className={styles['layout__container']}>
      <div className={styles['layout__sidebar']}>
        <Sidebar />
      </div>
      <div className={styles['layout__main']}>{children}</div>
    </div>
  )
}

// const styles = {
//   continer: css`
//     margin: 0 auto;
//     max-width: 1024px;
//     display: flex;
//     flex-direction: column;
//     & > * {
//       padding: ${rhythm(1)};
//       padding-top: ${rhythm(1.5)};
//     }
//     ${mq.min600(`
// 			flex-direction: row;
// 		`)}
//   `,
//   sidebar: css`
//     flex: 1;
//     position: relative;
//     margin-right: ${rhythm(1)};
//     ${mq.min600(`
//       :after {
//         background: #e6e6e6;
//         background: linear-gradient(180deg, #e6e6e6 0, #e6e6e6 48%, #fff);
//         position: absolute;
//         content: "";
//         width: 0.0625rem;
//         height: 33.75rem;
//         top: 30px;
//         right: 0;
//         bottom: 0;
//       }
//     `)}
//   `,
//   main: css`
//     flex: 2;
//   `,
// }
