import React from 'react'
import { css } from '@emotion/react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import { rhythm } from '../../../utils/typography'
import { SidebarDataQuery } from '../../../../gatsby-graphql'
import Company from './company'
import styles from './sidebar.module.scss'
import NavLink from '../../nav-link'

type TProps = {
	className?: string
}

export default function Sidebar({ className }: TProps) {
	const data: SidebarDataQuery = useStaticQuery(
		graphql`
      query SidebarData {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
	)

	return (
		<div className={styles['sidebar']}>
			<Company />
			<p>{data.site.siteMetadata.description}</p>
			<nav>
				<span>Power Ups</span>
				<ul>
					<NavLink to={`/bulk-actions-intro`}><img src={'/images/bulk-actions-icon.png'} />Bulk Actions</NavLink>
					<NavLink to={`/wishlist-intro`}><img style={{ transform: 'scale(1.3) translateY(-2px)' }} src={'/images/wishlist-icon.png'} />Wishlist</NavLink>
				</ul>
				<span>Guides</span>
				<ul>
					<NavLink to={`/reuthoriztion-guide`}>Re-Authorization</NavLink>
				</ul>
				<span>Policies</span>
				<ul>
					<NavLink to={`/privacy-policy/`}>Privacy Policy</NavLink>
					<NavLink to={`/terms-of-use/`}>Terms of Use</NavLink>
				</ul>
				{/* <NavLink to={`/cookie-policy/`}>Cookie Policy</NavLink> */}
				{/* </ul> */}
			</nav>
			{/* {children} */}
		</div>
	)
}

// const styles = {
//   continer: css`
//     margin: 0 auto;
//     display: flex;
//     flex-direction: column;
//     padding: ${rhythm(1)};
//     padding-top: ${rhythm(1.5)};
//   `,
//   h3: css``,
//   right: css``,
// }
