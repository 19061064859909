import Typography from "typography"
import githubTheme from "typography-theme-github"

githubTheme.baseFontSize = "16px"
githubTheme.baseLineHeight = 1.5 //24px
githubTheme.scaleRatio = 2

const typography = new Typography(githubTheme)

export default typography
export const rhythm = typography.rhythm
