import React from 'react'
import { css } from '@emotion/react'
import { useStaticQuery, Link, graphql, withPrefix } from 'gatsby'
import { rhythm } from '../../../utils/typography'
import { SidebarDataQuery } from '../../../../gatsby-graphql'
import useSiteMetadata from '../../../hooks/use-site-metadata'
import styles from './company.module.scss'
import NavLink from '../../nav-link'

type TProps = {
	className?: string
}

export default function Company({ className }: TProps) {
	const { company, description } = useSiteMetadata()

	return (
		<NavLink to="/" className={styles.container}>
			{/* <NavLink to="/"> */}
			<img
				src={withPrefix(company.thumbnail)}
				className={styles['container__photo']}
				width="32"
				height="32"
				alt={company.name}
			/>
			<h1>{company.name}</h1>
			{/* </NavLink> */}
		</NavLink>
	)
}

// const styles = {
//   continer: css`
//     /* margin: 0 auto; */
//     /* display: flex;
//     flex-direction: column;
//     padding: ${rhythm(1)};
//     padding-top: ${rhythm(1.5)}; */
//   `,
//   h3: css``,
//   right: css``,
// }
